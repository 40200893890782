const parseInitialState = (enrollmentForm) => {
  let formInitialState = {}
  enrollmentForm.forEach((field) => {
    switch (field.type) {
      case "tel":
      case "text":
      case "email":
      case "number":
      case "radio":
      case "scale":
        formInitialState[field.name] = ""
        break
      case "address":
        formInitialState[field.name] = {
          addressType: "",
          streetAddress: "",
          province: { value: "", label: "" },
          city: { value: "" },
          barangay: { value: "" },
          notes: "",
        }
        break
      case "schedule":
        formInitialState[field.name] = {
          day: { value: "", label: "" },
          time: { value: "", label: "" },
        }
        break
      case "select":
      case "diagnosis":
        formInitialState[field.name] = { value: "", label: "" }
        break
      case "date":
        formInitialState[field.name] = {
          month: {
            value: "",
          },
          date: {
            value: "",
          },
          year: "",
        }
        break
      case "hospital":
        formInitialState[field.name] = { value: "", label: "" }
        break
    }
  })

  return formInitialState
}

export const getFormInitialState = ({ state, module, formFields }) => {
  let enrollmentIntialState = parseInitialState(formFields)

  switch (module?.app) {
    case "medical-history":
      return Object.keys(state.selfRequest).length === 0
        ? {
            ...enrollmentIntialState,
            employee: {
              firstName: "",
              lastName: "",
              gender: "",
              birthday: {
                month: "",
                date: "",
                year: "",
              },
              site: "",
              department: "",
              employmentStart: "",
              mobileNumber: "",
              employeeNumber: "",
              email: "",
              currentWorkHours: "",
              gender: "",
            },
          }
        : state.selfRequest
    case "schedule":
      return Object.keys(state.scheduleState).length === 0
        ? {
            ...enrollmentIntialState,
          }
        : state.scheduleState
      break
  }
}
