import React, { Fragment, useContext, useState } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import { generateFormField } from "elements/Form/services/form"
import ConsentAndAuthorization from "../../../Intake/SelfRequest/PreferredSchedule/ConsentAndAuthorization"

import { intakeSelfRequestEmailTemplate } from "../../../Intake/utils/intakeSelfRequestEmailTemplate"
import { intakeSelfRequestZendeskTemplate } from "../../../Intake/utils/zendeskTemplates"
import { sendTeleconsultRequest } from "../../../Intake/services/sendTeleconsultRequest"
import { useMentalHealthFormFields } from "../hooks/useMentalHealthFormFields"
import { getSignedInUser } from "../../../Auth/services/user"
import { getFormInitialState } from "../hooks/useFormState"
import { AppContext } from "../../../../context/AppContext"
import { isObjectEmpty } from "services/general"

const BookingSchedule = ({ pageContext }) => {
  const [hasErrorSubmitting, setHasErrorSubmitting] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [isLoading, setLoading] = useState(false)

  const { selfRequest } = state
  const userData = getSignedInUser()?.userData
  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })

  const handleSuccessSubmit = async () => {
    setLoading(false)
    await dispatch({ type: "SET_SELF_REQUEST_COMPLETE" })
    navigate("/complete")
  }

  const handleErrorSubmit = ({ message }) => {
    setLoading(false)
    setHasErrorSubmitting(true)
  }

  const whichSchedulesAreDuplicates = (values) => {
    const schedules = [
      values.firstChoiceSchedule,
      values.secondChoiceSchedule,
      values.thirdChoiceSchedule,
    ]
    const uniqueArray = []
    const duplicates = []
    schedules.map((schedule, index) => {
      const dayTime = `${schedule?.day?.label} ${schedule?.time?.label}`
      if (uniqueArray.includes(dayTime)) {
        duplicates.push(`schedule${index + 1}.day`)
      } else {
        uniqueArray.push(dayTime)
      }
    })

    return duplicates
  }

  const handleSubmit = async (values, { setFieldError }) => {
    setLoading(true)

    const duplicates = whichSchedulesAreDuplicates(values)
    if (duplicates.length > 0) {
      duplicates.map((duplicate) => {
        setFieldError(duplicate, "Schedule must be unique", false)
      })
      setLoading(false)
    } else {
      setHasErrorSubmitting(false)

      const employee = {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        birthday: userData?.birthday,
        gender: userData?.gender,
        mobileNumber: userData?.mobileNumber,
        email: userData?.email,
        employeeNumber: userData?.employeeNumber,
        site: userData?.site,
        department: userData?.department,
        employmentStart: userData?.employmentStart,
      }

      const emergencyContact = userData.emergencyContact

      let newValues = {
        ...selfRequest,
        ...values,
        employee: employee,
        emergencyContact: emergencyContact,
      }

      sendTeleconsultRequest({
        requestData: newValues,
        ticketTemplate: intakeSelfRequestZendeskTemplate,
        emailTemplate: intakeSelfRequestEmailTemplate,
        callback: handleSuccessSubmit,
        errorCallback: handleErrorSubmit,
        type: "selfRequest",
      })
    }
  }

  const handleClickBack = () => {
    let backPath = pageContext.backPath

    if (state?.hasWishedToBeDead?.includes("Yes"))
      backPath = `/${pageContext?.module?.title}/assessment`

    return backPath
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      isPrivate
    >
      <Container isCentered desktop={6} fullhd={6}>
        <Formik
          initialValues={state.selfRequest}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, submitCount }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            errors,
                            submitCount,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              <ConsentAndAuthorization values={values} />
              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{
                  label: "Back",
                  link: handleClickBack(),
                }}
                submit={{
                  label: "Request Teleconsult",
                  loading: isLoading,
                  disabled:
                    !values?.terms?.length || !values?.informedConsent?.length,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default BookingSchedule
