import {
  GATSBY_AWS_SES_EMAIL_FROM,
  GATSBY_AWS_SES_EMAIL_TO,
  GATSBY_ENV,
} from "gatsby-env-variables"

import { sendEmail } from "../../../services/sendEmail"
import { sendToZendesk } from "../../../services/zendeskService"

// HIERARCHY OF CALLED FUNCTIONS
// sendTeleconsultRequest {
//   sendToZendesk {
//     generateZendeskRequestBody {
//       assignPriorityTag
//     }
//     zendeskApi
//   }
//   sendTeleconsultRequestToEmail {
//     sendEmail
//   }
//   callback
// }

export const sendTeleconsultRequest = async (config) => {
  let { errorCallback, callback, type } = config

  try {
    const response = await sendToZendesk(generateZendeskRequestBody(config))
    if (!!response?.data?.request.id) {
      // Only referral route needs the confirmation email via sendEmail - self request will have a Zendesk-triggered autoemail
      // We turned it off for referral route because in Zendesk, the requester is the referred employee, and referrer is cc'ed
      // If the autoemail trigger is on, the referred employee will receive the autoemail instead of the referrer
      if (type === "referral") {
        await sendTeleconsultRequestToEmail(config, response?.data.request.id)
      }
    }
    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback()
  }
}

export const sendTeleconsultRequestToEmail = (config, zendeskId) => {
  let { requestData, emailTemplate, type } = config
  const requestType = type === "selfRequest" ? "request" : "referral"
  const subject = `Your teleconsult ${requestType} has been received`
  const emailTo =
    type === "selfRequest"
      ? `${requestData?.employee?.email}`
      : `${requestData?.referrer?.email}`
  return sendEmail({
    to: GATSBY_AWS_SES_EMAIL_TO,
    cc: emailTo,
    from: GATSBY_AWS_SES_EMAIL_FROM,
    subject,
    html: emailTemplate(requestData, zendeskId),
  })
}

const assignPriorityTag = (ticketData, type) => {
  if (type === "referral") {
    switch (ticketData?.riskAssessmentScore.value) {
      case "Emergency":
        return "urgent"
      case "High":
        return "high"
      case "Moderate":
      default:
        return "normal"
      case "Low":
        return "low"
    }
  } else {
    if (ticketData?.cssrs?.length > 0) {
      const {
        suicidalIntentWithoutSpecificPlan,
        suicidalIntentWithPlan,
        suicidalBehavior,
      } = ticketData?.cssrs
      const alertQuestions = [
        suicidalIntentWithoutSpecificPlan,
        suicidalIntentWithPlan,
        suicidalBehavior,
      ]
      if (alertQuestions?.includes("Yes")) {
        return "urgent"
      } else return "normal"
    } else return "normal"
  }
}

export const computeKesslerScore = (kessler6) => {
  let answers = Object.values(kessler6)

  const getScore = (answer) => {
    switch (answer) {
      case "Always":
        return 0
      case "Often":
        return 1
      case "Sometimes":
        return 2
      case "Rarely":
        return 3
      case "Never":
        return 4
      default:
        return null
    }
  }

  let score = answers.reduce(
    (accumulator, currentValue) => accumulator + getScore(currentValue),
    0
  )
  return score
}

export const generateZendeskRequestBody = (config) => {
  let { requestData, ticketTemplate, type } = config
  let {
    employee,
    referrer,
    firstChoiceSchedule,
    secondChoiceSchedule,
    thirdChoiceSchedule,
  } = requestData
  let ccEmails = []

  let isEnvDevelop = GATSBY_ENV !== "production"

  if (employee?.personalEmail && type === "selfRequest")
    ccEmails.push({
      user_email: employee.email,
      user_name: `${employee.firstName} ${employee.lastName}`,
    })
  if (referrer?.email)
    ccEmails.push({
      user_email: isEnvDevelop ? GATSBY_AWS_SES_EMAIL_TO : `${referrer.email} `,
      user_name: `${referrer.firstName} ${referrer.lastName}`,
    })

  let tags = ["taskus", "taskus_mh", "new_request"]
  if (isEnvDevelop) tags.push("test")

  tags.push(employee.site.value)
  let employeeName = `${employee.firstName} ${employee.lastName}`
  let siteShorthand = employee.site.label.split(" (")[0]

  let subject = `${
    isEnvDevelop ? "[TEST] " : ""
  } [TaskUs ${siteShorthand}] ${employeeName}: Teleconsult Request`

  let requester = {
    name: employeeName,
    email: `${employee?.email}`,
    details: employee?.site?.label,
  }

  let customFields = []
  const PREFERRED_SCHEDULE_FIELD_ID = 900003419503
  const REFERRER_NAME_FIELD_ID = 900003620243
  const KESSLER_SCORE_FIELD_ID = 900008136543
  const MOBILE_NUMBER_FIELD_ID = 900007397186
  const HASHED_DETAILS_FIELD_ID = 900012853803

  if (type === "referral") {
    tags.push("refer")
    subject += ` (via ${referrer?.firstName} ${referrer?.lastName})`
    customFields.push({
      id: REFERRER_NAME_FIELD_ID,
      value: `${referrer?.firstName} ${referrer?.lastName}`,
    })
  } else {
    tags.push("self")
    const formattedSchedule2 = secondChoiceSchedule?.day?.value
      ? `; ${secondChoiceSchedule?.day?.value} ${secondChoiceSchedule?.time?.value}`
      : ``
    const formattedSchedule3 = thirdChoiceSchedule?.day?.value
      ? `; ${thirdChoiceSchedule?.day?.value} ${thirdChoiceSchedule?.time?.value}`
      : ``
    customFields.push({
      id: PREFERRED_SCHEDULE_FIELD_ID,
      value: `${firstChoiceSchedule?.day?.value} ${firstChoiceSchedule?.time?.value}${formattedSchedule2}${formattedSchedule3}`,
    })

    customFields.push({
      id: KESSLER_SCORE_FIELD_ID,
      value: computeKesslerScore(requestData),
    })
  }

  customFields.push({
    id: MOBILE_NUMBER_FIELD_ID,
    value: employee?.mobileNumber,
  })

  customFields.push({
    id: HASHED_DETAILS_FIELD_ID,
    value: JSON.stringify(requestData),
  })

  let requestBody = {
    type: "request",
    tags: tags,
    subject: subject,
    requester: requester,
    email_ccs: ccEmails,
    priority: assignPriorityTag(requestData, type),
    comment: { body: ticketTemplate(requestData) },
    custom_fields: customFields,
  }

  if (type === "selfRequest") {
    requestBody = { ...requestBody, custom_fields: customFields }
  }

  return requestBody
}
