import { bookingSchema } from "../utils/bookingSchema"

let { parseFormField } = require("services/airtable")

export const useMentalHealthFormFields = ({ formFields }) => {
  return {
    sectionFormFields: parseFormField(formFields),
    validationSchema: bookingSchema({ fields: formFields }),
  }
}
